<template>
Ustawienia
</template>

<script>
export default{
    name: "menuContext-settings"
}
</script>

<style>
</style>