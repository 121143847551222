<template>

  <div class="container-fluid bg-black full-height p-0">
    <div id="top" class="row gx-0 ">
      <div class="col-12 p-0">
        <Top />
      </div>
    </div> 
 
    <div class="row gx-0">
      <div class="d-none d-lg-block text-light col-lg-3 col-xl-2 col-xxl-3 d-block h-100 border-right p-0">
        <Menu />
      </div>
      <div class="col-lg-9 col-xl-10 bg-white col-xxl-9 p-0">
        <div class="position-relative">
          <div id="workspace" class="position-absolute w-100 p-0 m-0 d-block" style="left:0; top:0; overflow:hidden;">
            <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.919890489259!2d16.932363015803322!3d52.40812807979318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b4755783bf5%3A0xda11f8c5f526b8b8!2sStary%20Rynek%2C%2061-772%20Pozna%C5%84!5e0!3m2!1spl!2spl!4v1641296631479!5m2!1spl!2spl" style="border:0; display:block;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
   
</template>

<script>
import Top from "../elements/Top";
import Menu from "../elements/Menu";

export default {
    name: "Projects",
    components: {Top, Menu},
  
    methods: {
      adjustMapSize() {
          let map = document.getElementById("map");
          let menu = document.getElementById("menu");
          let topRow = document.getElementById("top").offsetHeight;
          let workspace = document.getElementById("workspace").offsetWidth;
       //  workspace = 1900;
           this.$store.dispatch({type:'resize', width:workspace});

        let width = workspace+"px";
        let height = (window.innerHeight - topRow)+"px";
          map.style.width = width;
          map.style.height = height;
          menu.style.height = height;
      },
  
    },
   
    mounted() {
      this.adjustMapSize();
     window.addEventListener('resize', this.adjustMapSize);
    },
    unmounted() {
      window.removeEventListener('resize', this.adjustMapSize);
    }
}
</script>

<style scoped>
.full-height {
  height: 100vh !important;
};

</style>