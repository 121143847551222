<template>

  <div class="container-fluid bg-black full-height p-0">
    <div id="top" class="row gx-0 ">
      <div class="col-12 p-0">
        <Top />
      </div>
    </div> 
 
    <div class="row gx-0">
      <div class="d-none d-lg-block text-light col-lg-3 col-xl-2 col-xxl-3 d-block h-100 border-right p-0">
        <Menu />
      </div>
      <div id="workspace" class="col-lg-9 col-xl-10 bg-white col-xxl-9 p-0">
          
      </div>
    </div>
  </div>
   
</template>

<script>
import Top from "../elements/Top";
import Menu from "../elements/Menu";

export default {
    name: "Settings",
    components: {Top, Menu},
  
    methods: {
      adjustSize() {
          let menu = document.getElementById("menu");
          let topRow = document.getElementById("top").offsetHeight;
          let workspace = document.getElementById("workspace");
       //  workspace = 1900;
           this.$store.dispatch({type:'resize', width:workspace});

        let height = (window.innerHeight - topRow)+"px";
          menu.style.height = height;
          workspace.style.height = height;
      },
  
    },
   
    mounted() {
     this.adjustSize();
     window.addEventListener('resize', this.adjustSize);
    },
    unmounted() {
      window.removeEventListener('resize', this.adjustSize);
    }
}
</script>

<style scoped>
.full-height {
  height: 100vh !important;
};

</style>