<template>
  <div class="container-fluid shadow-lg px-0" v-on="currentUser">
    <div class="row gx-0 bg-black text-light">
      <div class="col-6 px-0 text-start">
        <img src="../../assets/adaste-logo.svg" width="78" style="filter: grayscale(1);"/>
      </div>
       <div class="col-6 text-end p-2" id="{{workspace}}">
         {{ currentUser.email }}
         <button class="btn btn-success" @click='logOut()'>wyloguj</button>
        </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Top",
        methods: {
          logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        },
        computed: {
          currentUser() {
            return this.$store.state.auth.user;
          },
          workspace: {
            get() { return this.$store.state.globals.map.width}
          }
        }
    };
</script>

<style>
</style>