<template>
  <div class="container p-2 mt-lg-5">
    <div class="row gx-0">
        <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 border shadow-lg rounded bg-white p-4  px-lg-5">
                <FormLogo />
                <Form @submit="handleLogin" :validation-schema="schema" class="text-start">
                    <div class="form-group my-3">
                    <label for="username" class="text-secondary mb-1">Nazwa użytkownika (e-mail):</label>
                    <Field name="username" type="text" class="form-control form-control-lg" />
                    <ErrorMessage name="username" class="error-feedback text-danger" />
                    </div>
                    <div class="form-group my-3 ">
                    <label for="password" class="text-secondary mb-1">Hasło:</label>
                    <Field name="password" type="password" class="form-control form-control-lg" />
                    <ErrorMessage name="password" class="error-feedback text-danger" />
                    </div>

                    <div class="form-group text-center">
                    <button class="btn btn-success mt-3 py-3 pb-3 w-100  btn-lg btn-block" :disabled="loading">
                        <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm me-2"
                        ></span>
        
                        <span class="h6">zaloguj</span>
                    </button>
                    </div>

                    <div class="form-group mt-3">
                      <div v-if="message" class="alert alert-danger p-1 py-2 text-center" role="alert">
                          {{ message }}
                      </div>
                    </div>
                </Form>
        
        </div>
    </div>
  </div>
  <div class="container mt-3">
      <div class="row">
        <div class="col-10 offset-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="container p-0 mt-2">
                <div class="row gx-0">
                    <div class="col-6 text-start p-0 h6">
                      <router-link to="reset-password">
                        <font-awesome-icon :icon="['fas','chevron-right']" class="me-2 small" color="blue" />reset hasła
                      </router-link>
                    </div>  
                    <div class="col-6 text-end p-0 h6">
                      <router-link to="account-request">
                        <font-awesome-icon :icon="['fas','chevron-right']" class="me-2 small" color="blue" />prośba o dostęp
                      </router-link>
                    </div>
                </div>
                <div class="row mt-4 mt-lg-5">
                    <div class="col-12 text-secondary small">
                        <span class="d-block">&copy; 2021 adaste. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import FormLogo from "../elements/FormLogo.vue";

export default {
  name: 'Login',
    components: {
    Form,
    Field,
    ErrorMessage,
    FormLogo
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().email('Podaj poprawny adres e-mail').required("Podaj poprawny adres e-mail!"),
      password: yup.string().required("Podaj hasło!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
        this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
