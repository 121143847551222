<template>
<div class="d-none position-absolute p-2 libraryTable" style="top:0; left:0; right:0;"  id="TablePersons">
    <div class="container-fluid  bg-dark text-light border shadow-sm rounded mb-3">
        <div class="row gx-0 py-2">
            <div class="col-12">
                <span class="h4">OSOBY</span>
            </div>
        </div>
        <div class="row gx-0 py-3">
            <div class="col-12">
                filtry do tabeli osób
            </div>
        </div>
    </div>
     <div class="container-fluid p-0 m-0" style="overflow-x:hidden; overflow-y:scroll; height:72vh; max-height:72vh;">
        <div class="row gx-0 h6 border-bottom py-2 fw-bold bg-light position-sticky" style="top:0;">
            <div class="col-1">
                Lp.
            </div>
            <div class="col-4">
                imię i nazwisko
            </div>
            <div class="col-2">
                kontakt
            </div>
       
            <div class="col-2">
                miejscowość
            </div>
            <div class="col-3">
                specjalizacja
            </div>
        </div>
         <div v-for="entry, key in getResult" :key="entry.id" class="row gx-0 py-1 tableRow" :id="'entry'+entry.id" @click="modal.show()" >
            <div class="col-1">{{ key }}.</div>
            <div class="col-4 text-start">{{ entry.person_basics.name }} {{ entry.person_basics.surname }}</div>
            <div class="col-2">{{ entry.contact_mobile[0].number }}</div>
            <div class="col-2">{{ entry.contact_address[0].address_city }}</div>
            <div class="col-3">1</div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row gx-0 border rounded h6 fw-bold m-0 py-3 bg-light position-sticky" style="bottom:-1px;">
            <div class="col-12">
                stronicowanie, narzędzia itp.
            </div>
        </div>
    </div>

    
  <div class="modal fade" ref="personFormModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header bg-dark">
            <div class="container-fluid">
                <div class="row gx-0">
                    <div class="col-12 col-md-6">
                        <input type="text" class="form-control p-2" placeholder="Imię i nazwisko">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body">
            
            <div class="container-fluid text-start">
              <div class="row gx-0">
                  <div class="col-12 col-xl-3  p-3">
                     <span class="h5 fw-bold">Dane adresowe</span>

                     <div class="container-fluid mt-3">
                        <div class="row gx-0">
                            <div class="col-12">
                                <div class="accordion" id="accordionExample">
                             
                                <div class="accordion-item new-item">
                                    <h2 class="accordion-header" id="headingNewAddress">
                                    <button class="accordion-button collapsed pe-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNewAddress" aria-expanded="false" aria-controls="collapseThree">
                                        <input type="text" class="form-control p-2 me-2" placeholder="nowy adres">
                                    </button>
                                    </h2>
                                    <div id="collapseNewAddress" class="accordion-collapse collapse collapse-new" aria-labelledby="headingNewAddress" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                           <div class="container-fluid">
                                                <div class="row gx-0">
                                                    <div class="col-12 p-2">
                                                        <span class="h6">Ulica</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                    <div class="col-6 p-2">
                                                        <span class="h6">Nr budynku</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                    <div class="col-6 p-2">
                                                        <span class="h6">Nr lokalu</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                </div>
                                                <div class="row gx-0">
                                                    <div class="col-4 p-2">
                                                        <span class="h6">Kod poczt.</span>
                                                        <input type="text" placeholder="00-000" class="form-control p-2">
                                                    </div>
                                                    <div class="col-8 p-2">
                                                        <span class="h6">Miejscowość</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>                            
                                                </div>
                                                <div class="row gx-0">
                                                    <div class="col-12 p-2">
                                                        <span class="h6">Województwo</span>
                                                        <select class="form-select">
                                                            <option value="1">dolnośląskie</option>
                                                            <option value="2">kujawsko-pomorskie</option>
                                                            <option value="3">lubelskie</option>
                                                            <option value="4">lubuskie</option>
                                                            <option value="5">łódzkie</option>
                                                            <option value="6">małopolskie</option>
                                                            <option value="7">mazowieckie</option>
                                                            <option value="8">opolskie</option>
                                                            <option value="9">podkarpackie</option>
                                                            <option value="10">podlaskie</option>
                                                            <option value="11">pomorskie</option>
                                                            <option value="12">śląskie</option>
                                                            <option value="13">świętokrzyskie</option>
                                                            <option value="14">warmińsko-mazurskie</option>
                                                            <option value="15">wielkopolskie</option>
                                                            <option value="16">zachodnio-pomorskie</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 p-2">
                                                        <span class="h6 d-block">Kraj</span>
                                                        <select class="form-select" disabled>
                                                            <option value="pl">Polska</option>
                                                        </select>
                                                    </div>                            
                                                </div>
                                                <div class="row gx-0 mt-3 text-center">
                                                    
                                                    <div class="col-12 p-2">
                                                        <button class="btn btn-success px-3">zapisz</button>
                                                    </div>                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row gx-0 mt-4">
                            <div class="col-12 mb-4">
                                <div class="form-check form-switch w-50">
                                    <input type="checkbox" checked class="form-check-input">
                                    <label class="form-check-label">na liście</label>
                                </div>
                                <h5 class="fw-bold mb-0">DOM</h5>
                                <h6 class="m-0">Cieplicka 18, 60-482 Poznań</h6>
                                <h6 class="m-0">woj. wielkopolskie</h6>
                                <h6 class="m-0">POLSKA</h6>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="form-check form-switch w-50">
                                    <input type="checkbox" class="form-check-input">
                                    <label class="form-check-label">na liście</label>
                                </div>
                                <h5 class="fw-bold mb-0">Biuro Garbary</h5>
                                <h6 class="m-0">Garbary 40, 60-470 Poznań</h6>
                                <h6 class="m-0">woj. wielkopolskie</h6>
                                <h6 class="m-0">POLSKA</h6>
                            </div>
                        </div>
                     </div>

                  
                  </div>


                   <div class="col-12 col-xl-3 p-3">
                     <span class="h5 fw-bold">Adresy e-mail</span>
                     <div class="container-fluid mt-3 mb-5">
                        <div class="row gx-0">
                            <div class="col-12">
                                <div class="accordion" id="accordionExample2">
                             
                                <div class="accordion-item new-item">
                                    <h2 class="accordion-header" id="headingNewEmail">
                                    <button class="accordion-button collapsed pe-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNewEmail" aria-expanded="false" aria-controls="collapseThree">
                                        <input type="text" class="form-control p-2 me-2" placeholder="nowy adres e-mail">
                                    </button>
                                    </h2>
                                    <div id="collapseNewEmail" class="accordion-collapse collapse collapse-new" aria-labelledby="headingNewEmail" data-bs-parent="#accordionExample2">
                                    <div class="accordion-body">
                                           <div class="container-fluid">
                                                <div class="row gx-0">
                                                    <div class="col-12 p-2">
                                                        <span class="h6">nazwa</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                    <div class="col-12 p-2">
                                                        <span class="h6">adres e-mail</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                </div>
                                              
                                                <div class="row gx-0 mt-3 text-center">
                                                    <div class="col-12 p-2">
                                                        <button class="btn btn-success px-3">zapisz</button>
                                                    </div>                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row gx-0 mt-4 mb-2 bg-light">
                            <div class="col-9 px-2 fw-bold">
                                adres e-mail
                            </div>
                            <div class="col-3 text-center">
                                podst.
                            </div>
                        </div>
                        <div class="row gx-0 pb-3 ">
                            <div class="col-9 px-2">
                                <h6 class="text-primary m-0">jan@kowalski.com</h6>
                                <small>professional</small>
                            </div>
                            <div class="col-3 text-center">
                                <div class="form-check d-inline-block text-center">
                                    <input type="radio" checked class="form-check-input">
                                </div>
                            </div>
                        </div>
                        <div class="row gx-0 pb-3 ">
                            <div class="col-9 px-2">
                                <h6 class="text-primary m-0">janek@gmail.com</h6>
                                <small>personal</small>
                            </div>
                            <div class="col-3 text-center">
                                <div class="form-check d-inline-block text-center">
                                    <input type="radio" class="form-check-input">
                                </div>
                            </div>
                        </div>
                     </div>

                    <span class="h5 fw-bold">Telefony komórkowe</span>
                     <div class="container-fluid mt-3 mb-5">
                        <div class="row gx-0">
                            <div class="col-12">
                                <div class="accordion" id="accordionExample3">
                             
                                <div class="accordion-item new-item">
                                    <h2 class="accordion-header" id="headingNewMobile">
                                    <button class="accordion-button collapsed pe-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNewMobile" aria-expanded="false" aria-controls="collapseThree">
                                        <input type="text" class="form-control p-2 me-2" placeholder="nowy tel. komórkowy">
                                    </button>
                                    </h2>
                                    <div id="collapseNewMobile" class="accordion-collapse collapse collapse-new" aria-labelledby="headingNewMobile" data-bs-parent="#accordionExample3">
                                    <div class="accordion-body">
                                           <div class="container-fluid">
                                                <div class="row gx-0">
                                                     <div class="col-12 p-2">
                                                        <span class="h6">nazwa</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                    <div class="col-12 p-2">
                                                        <span class="h6">numer telefonu</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                </div>
                                              
                                                <div class="row gx-0 mt-3 text-center">
                                                    <div class="col-12 p-2">
                                                        <button class="btn btn-success px-3">zapisz</button>
                                                    </div>                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row gx-0 mt-4 mb-2 bg-light">
                            <div class="col-9 px-2">
                                nr telefonu
                            </div>
                            <div class="col-3 text-center">
                                podst.
                            </div>
                        </div>
                        <div class="row gx-0 pb-3 ">
                            <div class="col-9 px-2">
                                <h6 class="m-0">723 233 112</h6>
                                <small>personal</small>
                            </div>
                            <div class="col-3 text-center">
                                <div class="form-check d-inline-block text-center">
                                    <input type="radio" checked class="form-check-input">
                                </div>
                            </div>
                        </div>
                       
                     </div> 

                    <span class="h5 fw-bold">Telefony stacjonarne</span>
                     <div class="container-fluid mt-3 mb-5">
                        <div class="row gx-0">
                            <div class="col-12">
                                <div class="accordion" id="accordionExample">
                             
                                <div class="accordion-item new-item">
                                    <h2 class="accordion-header" id="headingNewPhone">
                                    <button class="accordion-button collapsed pe-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNewPhone" aria-expanded="false" aria-controls="collapseThree">
                                        <input type="text" class="form-control p-2 me-2" placeholder="nowy tel. stacjonarny">
                                    </button>
                                    </h2>
                                    <div id="collapseNewPhone" class="accordion-collapse collapse collapse-new" aria-labelledby="headingNewPhone" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                           <div class="container-fluid">
                                                <div class="row gx-0">
                                                     <div class="col-12 p-2">
                                                        <span class="h6">nazwa</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                    <div class="col-12 p-2">
                                                        <span class="h6">numer telefonu</span>
                                                        <input type="text" class="form-control p-2">
                                                    </div>
                                                </div>
                                              
                                                <div class="row gx-0 mt-3 text-center">
                                                    <div class="col-12 p-2">
                                                        <button class="btn btn-success px-3">zapisz</button>
                                                    </div>                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row gx-0 mt-4 mb-2 bg-light">
                            <div class="col-9 px-2">
                                nr telefonu
                            </div>
                            <div class="col-3 text-center">
                                podst.
                            </div>
                        </div>
                        <div class="row gx-0 pb-3 ">
                            <div class="col-9 px-2">
                                <h6 class="m-0">61 623 12 15</h6>
                                <small>professional</small>
                            </div>
                            <div class="col-3 text-center">
                                <div class="form-check d-inline-block text-center">
                                    <input type="radio" checked class="form-check-input">
                                </div>
                            </div>
                        </div>
                       
                     </div> 
                  </div>


                  <div class="col-12 col-xl-6 p-3 border-start">
                     <span class="h5 fw-bold d-block pt-1">Kompetencje</span>
                        <div class="container-fluid p-3">
                            <div class="row gx-0">
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Kierownik Budowy</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Usługi ogólnobudowlane</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Geodeta</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Projektant</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Architekt</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Roboty ziemne</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Murarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Konstruktur</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Elektryk</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Dekarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Stolarka drzwiowa i okienna</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Elektronik</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Hydraulik</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Instalator CO/CW</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Tynkarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Szpachlarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Malarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Płytkarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Biały montaż</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Stolarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Glazurnik</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Brukarz</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Roboty drogowe</label>
                                    </div>
                                </div>
                                <div class="col-4 py-1">
                                    <div class="form-check form-switch">
                                     <input type="checkbox" class="form-check-input">
                                     <label>Sprzątanie</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                  </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Anuluj</button>
          <button type="button" class="btn btn-primary">Zapisz</button>
        </div>
      </div>
    </div>
  </div>
</div>


</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    name: "TablePersons",
    data() {
        return {
            getResult: null,
            modal: null
        }
    },
    methods: {
        async loadPersons() {
          try {
                const res = await axios.get("https://api.adaste.com/api/persons");
                const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
                };
                this.getResult=null;
                console.log(result);
                this.getResult = result.data;
            } catch (err) {
                this.getResult = err.response?.data || err;
            }
        }
    },
    mounted() {
        this.modal = new Modal(this.$refs.personFormModal);
        this.loadPersons();
    },

    unmounted() {
        this.modal.hide();    
    }
}
</script>

<style>
  .tableRow:hover {
      color: red !important;
      cursor: pointer !important;
  }
</style>