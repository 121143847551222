<template>
<span class="h4 fw-bold d-block my-3">KARTOTEKI</span>
<div class="container-fluid text-start px-4">
    <div class="row gx-0 my-3 library" data-table="TableProjects" @click.prevent="showLibraryTable('TableProjects')">
        <div class="col-2 text-center">
            <i class="fas fa-project-diagram"></i>
        </div>
        <div class="col-10">
            <span class="h6">Projekty</span>
        </div>
    </div>
    <div class="row gx-0 my-3 library" data-table="TableCompanies" @click.prevent="showLibraryTable('TableCompanies')">
        <div class="col-2 text-center">
            <i class="fas fa-industry fa-lg"></i>
        </div>
        <div class="col-10">
            <span class="h6">Firmy</span>
        </div>
    </div>
    <div class="row gx-0 my-3 library" data-table="TablePersons" @click.prevent="showLibraryTable('TablePersons')">
        <div class="col-2 text-center">
            <i class="fas fa-user fa-lg"></i>
        </div>
        <div class="col-10">
           <span class="h6">Osoby</span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "menuContext-libraries",
    methods: {
        showLibraryTable(table) {
            
                let tables = document.getElementsByClassName("libraryTable");
                [].forEach.call(tables, function(el) {
                 el.classList.add("d-none");                    
               });
               
               document.getElementById(table).classList.remove("d-none");
               document.getElementById(table).classList.add("d-block");
            }      
    }
}
</script>

<style>
.library:hover {
      color: red !important;
      cursor: pointer !important;
}
</style>