<template>
<div class="container-fluid h-100 px-0">
    <div class="row gx-0 mb-3 border-bottom">
        <div class="col-12 px-2">
            <span class="h5 d-block fw-bold">Wybierz źródło danych</span>
        </div>
    </div>
    <div class="row gx-0">
        <div class="col-12 px-2">
            <div class="container-fluid text-start px-4">            
                <div  v-for="(source, token) in apiSources" v-bind:key="{ token }" @click="changeSource(token)" v-bind:data-token="token" v-bind:class="[current == token ? sourceStyle.active : sourceStyle.nonActive]" v-bind:style="[current == token ? cursor.active : cursor.none]" >
                    <div class="col-2 text-center">
                        <font-awesome-icon v-if="current == token" :icon="['far', 'dot-circle']" class="text-primary"/>
                        <font-awesome-icon v-if="current != token" :icon="['far', 'circle']" class="text-primary"/>
                    </div>
                    <div class="col-10">
                         {{ source }}
                    </div>
                </div>
            </div>
        </div>    
    </div>    
</div>
</template>

<script>

export default {
    name: "menuContext-imports",
    data: function() {
       return {
       current: JSON.parse(localStorage.imports)['currentApiSource'],
       apiSources: this.$store.state.globals.importApiSources.sources,
       sourceStyle: {
           'active': "row gx-0 py-1 text-danger fw-bold apiSource",
           'nonActive': "row gx-0 py-1 cursor-pointer apiSource",
       },
       cursor: {
           'active': 'cursor: default',
           'none': 'cursor:pointer'
       }
       }
    },
    methods: {
        changeSource(src) {
            let importsStorage = JSON.parse(localStorage.imports);
            importsStorage['currentApiSource']= src;
            localStorage.setItem('imports', JSON.stringify(importsStorage));    
            this.current = src;

            document.getElementById('narrowSandboxResults').value='';
            document.getElementById('workspace').scrollTop = 0;
            document.getElementById('refreshSandbox').click();
        }
        
    },

}
</script>

<style>
</style>