<template>
    <div class="mb-5 d-none">
        <font-awesome-icon :icon="['fab', 'accusoft']" class="fa-8x" @click.prevent="this.$router.push('/');" />
        <h1 class="fw-bold">adaste</h1>
    </div>
    <div class="mb-5">
        <img src="../../assets/adaste-logo.svg">
    </div>    
</template>

<script>
export default ({
    "name": "FormLogo"
})
</script>

<style scoped>

</style>
