<template>

  <div class="container-fluid bg-black full-height p-0">
    <div id="top" class="row gx-0 ">
      <div class="col-12 p-0">
        <Top />
      </div>
    </div> 
 
    <div class="row gx-0">
      <div class="d-none d-lg-block text-light col-lg-3 col-xl-2 col-xxl-3 d-block h-100 border-right p-0">
        <Menu />
      </div>
      <div id="workspace" class="col-lg-9 col-xl-10 bg-white col-xxl-9 px-0" style="overflow-y: auto; overflow-x: hidden">
        <div class="container-fluid border-bottom bg-light shadow" style="position:sticky; top:0;">
          <div class="row gx-0">
            <div class="col-4 p-3 py-3">
              <input id="narrowSandboxResults" placeholder="szukaj w wynikach" type="text" class="form-control p-3 py-3">
              
            </div>
          </div>
        </div>
        
          <div class="container-fluid mt-3 px-3 text-start" id="sandboxResults">
            <div v-for="entry in getResult" :key="entry.id" class="row gx-0" :id="'entry'+entry.id">
              <div class="col-4">
                <small class="text-secondary">{{ entry.published }}</small>
              </div>
              <div class="col-8 text-end">
                
              </div>
              <div class="col-12 px-0 mb-2">
                <small class="text-info">{{ entry.source }}</small>
                <span class="h6 fw-bold m-0 d-block">{{ entry.entry }}</span>
                
                <a class="small d-inline-block" style="overflow-x:hidden; font-size:0.75em;" :href="entry.link" target="_new">{{entry.link}}</a>
                </div>
              <div class="col-8 mt-1 mr-2 px-0 pb-3 border-bottom">
                  <span class="pill bg-success text-light p-1 px-2 rounded">nowe</span>
              </div>
              <div class="col-4 mt-1 mr-2 px-0 pb-3 text-end border-bottom">
                  <button class="btn archiveBtn btn-outline-secondary btn-sm" data-on="btn-secondary" data-off="btn-outline-secondary" :data-id="entry.id" @click="prepareToArchive($event)">archiwizuj</button>
              </div>
            </div>
          </div>

        <div class="container-fluid border-top bg-light" style="position:sticky; bottom:0; border-top-color: black !important">
          <div class="row gx-0">
            <div class="col-4 py-3 px-3 text-start">
              <span class="h6">zaznaczone:</span>
              <button :disabled="archiveCounter === 0" v-bind:class="[archiveCounter >0 ? archiveActionTrue : archiveActionFalse ]" @click="putToArchives()">
                do archiwum <span v-if="archiveCounter>0">({{archiveCounter}})</span>
              </button>
              
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
<input id="refreshSandbox" type="hidden" @click="refreshList()">  
</template>

<script>
import Top from "../elements/Top";
import Menu from "../elements/Menu";
import axios from 'axios';

export default {

    name: "Imports",
    components: {Top, Menu},
    data() {
    return {
      currentSource: localStorage.imports.currentApiSource,
      getResult: null,
      archiveCounter: 0,
      archiveActionTrue: "btn btn-sm mx-1 btn-danger",
      archiveActionFalse: "btn btn-sm mx-1 btn-outline-danger"
    }
  },
  
    methods: {
      adjustSize() {
          let menu = document.getElementById("menu");
          let topRow = document.getElementById("top").offsetHeight;
          let workspace = document.getElementById("workspace");
       //  workspace = 1900;
           this.$store.dispatch({type:'resize', width:workspace});

        let height = (window.innerHeight - topRow)+"px";
          menu.style.height = height;
          workspace.style.height = height;
      },

      async getData() {
        let src;
          try {
            document.getElementById('sandboxResults').style.opacity= 0;
            this.source !== 'all' ? src = '?sndsrc='+this.source : src = "";
            const res = await axios.get("https://api.adaste.com/api/sandbox"+src);
            const result = {
              status: res.status + "-" + res.statusText,
              headers: res.headers,
              data: res.data,
            };
             this.getResult=null;
            this.getResult = result.data;
          } catch (err) {
            this.getResult = err.response?.data || err;
          }
            document.getElementById('sandboxResults').style.opacity= 1;
        },
  
      refreshList() {
        this.currentSource = JSON.parse(localStorage.imports)['currentApiSource'];
        this.archiveCounter = 0;
      
        this.clearArchiveReady();
        this.getData();
        },

      prepareToArchive(event) {
         if (event.target.classList.contains( event.target.getAttribute('data-off')))
           {
              event.target.classList.remove(event.target.getAttribute('data-off'));
              event.target.classList.add(event.target.getAttribute('data-on'));
              this.archiveCounter ++;
          } else {
              event.target.classList.remove(event.target.getAttribute('data-on'));
              event.target.classList.add(event.target.getAttribute('data-off'));
              this.archiveCounter >0 ? this.archiveCounter = this.archiveCounter -1 : null;
          }
      },

      clearArchiveReady() {
       
        let items = document.getElementsByClassName('archiveBtn');
        for (let i=0; i<items.length; i++) {
           if (items[i].classList.contains( items[i].getAttribute('data-on'))) {
               items[i].classList.remove(items[i].getAttribute('data-on'));
              items[i].classList.add(items[i].getAttribute('data-off'));
           }
        }
      },

      putToArchives() {
        let items = document.getElementsByClassName('archiveBtn btn-secondary');
        let archiveFiles = [];
        for (let i=0; i<items.length; i++) {
          archiveFiles.push( parseInt(items[i].getAttribute('data-id')));
        }

          try {
          
          const params = new URLSearchParams();
          params.append('data', JSON.stringify(archiveFiles));
                 
          axios.post("https://api.adaste.com/api/sandbox/putToArchives", params);
          /*
             .then( (response) => {
               console.log(response);
            });
          */
          } catch (err) {
            console.log(err);
            this.getResult = err.response ?.data || err;
          } finally {
            for (let i=0; i< archiveFiles.length; i++) {
              document.getElementById('entry'+archiveFiles[i]).classList.add("d-none");
            }
            this.refreshList();
          }
       
      }
    },
   
    mounted() {
      this.adjustSize();
      window.addEventListener('resize', this.adjustSize);
      document.getElementById('refreshSandbox').click();
    },
    
    unmounted() {
      window.removeEventListener('resize', this.adjustSize);
    },

    computed: {
        source() { return this.currentSource; }
    },

}
</script>

<style scoped>
.full-height {
  height: 100vh !important;
};
</style>