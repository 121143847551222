<template>
  <Top v-if="currentUser" />
  <div v-if="!currentUser">
    <div class="my-5 pt-4">
    <font-awesome-icon :icon="['fab', 'accusoft']" class="fa-10x mt-2" color="red"/>
    <h1 class="fw-bold">adaste</h1>
    </div>
    <div class="hello">
      <button v-if="!currentUser" class="btn btn-success mb-3" @click='logIn()'>login</button>
    </div>
  </div>
</template>

<script>
import Top from "../elements/Top"
export default {
  name: 'Home',
  components: {Top},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
   methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    logIn() {
      this.$router.push('/login');
    }
  },

  beforeMount() {
    if (localStorage.getItem('user')) {
        window.location.href ='/dashboard';
      } 
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
