<template>
<div class="container-fluid h-100">
    <div class="row gx-0 h-100">
        <div class="col-12 h-100">
        Narzędzia panelu głównego
        </div>
    </div>
</div>
</template>

<script>
export default{
    name: "menuContext-dashboard"
}
</script>

<style>
</style>