import { createStore } from "vuex";
import { auth } from "../store/auth.module";
import {globals} from "../store/globals.module";

const store = createStore({
  modules: {
    auth,
    globals,
  },
});

export default store;