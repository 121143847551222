let apiSources = {
     "current": 'all',
     "sources": {
         'all': "wszystkie",
         'krakow_pl': 'Portal Miejski Kraków',
         'zielonagora_pl': 'Portal Miejski Zielona Góra',
         'koszalin_pl': 'Portal Miejski Koszalin',
         'bydgoszcz_pl': 'Portal Miejski Bydgoszcz'
     }
};

localStorage.getItem('imports') ? null : localStorage.setItem('imports', JSON.stringify({"currentApiSource":apiSources['current']}));


export const globals = {
    state: {
        map: {
            width: 10,
            height: 0
        },
        importApiSources: apiSources
    },
    actions: {
        resize({commit}, values) {
           commit('resizeMap', values);
        },
        setSandboxSource({commit}, newSource) {
            commit('newSandboxSource', newSource);
        }
    },
    mutations: {
        resizeMap(state, values) {
            state.map.width = values.width;
        },
        newSandboxSource(state, newSource) {
            state.importApiSources.current = newSource;
         
        }
    }
}