<template>
<div class="d-none position-absolute p-2 libraryTable" style="top:0; left:0; right:0;"  id="TableProjects">
    <div class="container-fluid  bg-dark text-light border shadow-sm rounded mb-3">
        <div class="row gx-0 py-2">
            <div class="col-12">
                <span class="h4">PROJEKTY</span>
            </div>
        </div>
        <div class="row gx-0 py-3">
            <div class="col-12">
                filtry do tabeli projektow
            </div>
        </div>
    </div>
    <div class="container-fluid p-0 m-0" style="overflow-x:hidden; overflow-y:scroll; height:72vh !important; max-height:72vh;">
        <div class="row gx-0 h6 border-bottom py-2 fw-bold bg-light position-sticky" style="top:0;">
            <div class="col-1">
                Lp.
            </div>
            <div class="col-4">
                Nazwa
            </div>
            <div class="col-3">
                Developer
            </div>
            <div class="col-3">
                Miejscowość
            </div>
            <div class="col-1">
                status
            </div>
        </div>
        <div v-for="entry, key in getResult" :key="entry.id" class="row gx-0 py-1 tableRow" :id="'entry'+entry.id" @click="modal.show()">
            <div class="col-1">{{ key }}.</div>
            <div class="col-4 text-start">{{ entry.project_basics.label }}</div>
            <div class="col-3">1</div>
            <div class="col-3">{{ entry.project_basics.address_city }}</div>
            <div class="col-1">1</div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row gx-0 border rounded h6 fw-bold m-0 py-3 bg-light position-sticky" style="bottom:-1px;">
            <div class="col-12">
                stronicowanie, narzędzia itp.
            </div>
        </div>
    </div>
    
    <div class="modal fade" ref="projectFormModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header bg-dark">
            <div class="container-fluid">
                <div class="row gx-0">
                    <div class="col-6">
                        <input type="text" class="form-control p-2" placeholder="nazwa projektu">
                    </div>
                </div>
            </div>
         
          
        </div>
        <div class="modal-body">
          <div class="container-fluid text-start">
              <div class="row gx-0">
                  <div class="col-12 col-lg-3 p-3">
                     <span class="h5 fw-bold">Lokalizacja</span>
                     <div class="container-fluid mt-3">
                    
                        <div class="row gx-0">
                            <div class="col-12 p-2">
                                <span class="h6">Ulica</span>
                                <input type="text" class="form-control p-2">
                            </div>
                            <div class="col-6 p-2">
                                <span class="h6">Nr budynku</span>
                                <input type="text" class="form-control p-2">
                            </div>
                            <div class="col-6 p-2">
                                <span class="h6">Nr lokalu</span>
                                <input type="text" class="form-control p-2">
                            </div>
                        </div>
                        <div class="row gx-0">
                            <div class="col-4 p-2">
                                <span class="h6">Kod pocztowy</span>
                                <input type="text" placeholder="00-000" class="form-control p-2">
                            </div>
                            <div class="col-8 p-2">
                                <span class="h6">Miejscowość</span>
                                <input type="text" class="form-control p-2">
                            </div>                            
                        </div>
                        <div class="row gx-0">
                            <div class="col-12 p-2">
                                <span class="h6">Województwo</span>
                                <select class="form-select">
                                    <option value="1">dolnośląskie</option>
                                    <option value="2">kujawsko-pomorskie</option>
                                    <option value="3">lubelskie</option>
                                    <option value="4">lubuskie</option>
                                    <option value="5">łódzkie</option>
                                    <option value="6">małopolskie</option>
                                    <option value="7">mazowieckie</option>
                                    <option value="8">opolskie</option>
                                    <option value="9">podkarpackie</option>
                                    <option value="10">podlaskie</option>
                                    <option value="11">pomorskie</option>
                                    <option value="12">śląskie</option>
                                    <option value="13">świętokrzyskie</option>
                                    <option value="14">warmińsko-mazurskie</option>
                                    <option value="15">wielkopolskie</option>
                                    <option value="16">zachodnio-pomorskie</option>
                                </select>
                            </div>
                            <div class="col-12 p-2">
                                <span class="h6 d-block">Kraj</span>
                                <select class="form-select" disabled>
                                    <option value="pl">Polska</option>
                                </select>
                            </div> 
                             <div class="row gx-0 pt-5">
                                <div class="col-6 p-2">
                                    <span class="h6">GPS Lat:</span>
                                    <input type="text" class="form-control p-2">
                                </div><div class="col-6 p-2">
                                    <span class="h6">GPS Long:</span>
                                    <input type="text" class="form-control p-2">
                                </div>
                            </div>                           
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-lg-4 p-3">
                     <span class="h5 fw-bold d-block">Uczestnicy</span>
                     <div class="container-fluid mt-4">
                        <div class="row gx-0">
                           <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">INWESTOR</b>
                                            <div class="px-0">
                                                <h6 class="m-0 mb-1">AKROPOL INWESTYCJE</h6>
                                                <h6 class="m-0">tel. 61 332 22 23</h6>
                                                <span class="">kom. 783 322 123</span>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body bg-light">
                                        <div class="container-fluid">
                                          
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    wybierz:
                                                </div>
                                                <div class="col-9">
                                                    <select class="form-select">
                                                        <option value="1">AKROPOL INWESTYCJE</option>
                                                        <option value="2">Monday Development Sp. z o.o.</option>
                                                        <option value="3">Grupa Invest</option>
                                                        <option value="4">Echo Investments</option>
                                                        <option value="5">ADASTE Przemysław Stelmaszyk</option>
                                                        <option value="5">Wechta</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">WYKONAWCA GENERALNY</b>
                                            <div class="px-0">
                                                <h6 class="m-0 mb-1">AKROPOL INWESTYCJE</h6>
                                                <h6 class="m-0">tel. 61 332 22 23</h6>
                                                <span class="">kom. 783 322 123</span>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body bg-light">
                                        <div class="container-fluid">
                                          
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    wybierz:
                                                </div>
                                                <div class="col-9">
                                                    <select class="form-select">
                                                        <option value="1">AKROPOL INWESTYCJE</option>
                                                        <option value="2">Monday Development Sp. z o.o.</option>
                                                        <option value="3">Grupa Invest</option>
                                                        <option value="4">Echo Investments</option>
                                                        <option value="5">ADASTE Przemysław Stelmaszyk</option>
                                                        <option value="5">Wechta</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseTwo">
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">Nadzór</b>
                                            <div class="px-0">
                                                <h6 class="m-0 mb-1">AKROPOL INWESTYCJE</h6>
                                                <h6 class="m-0">tel. 61 332 22 23</h6>
                                                <span class="">kom. 783 322 123</span>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body bg-light">
                                        <div class="container-fluid">
                                          
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    wybierz:
                                                </div>
                                                <div class="col-9">
                                                    <select class="form-select">
                                                        <option value="1">AKROPOL INWESTYCJE</option>
                                                        <option value="2">Monday Development Sp. z o.o.</option>
                                                        <option value="3">Grupa Invest</option>
                                                        <option value="4">Echo Investments</option>
                                                        <option value="5">ADASTE Przemysław Stelmaszyk</option>
                                                        <option value="5">Wechta</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">KIEROWNIK BUDOWY</b>
                                            <div class="px-0">
                                                <h6 class="m-0 mb-1">Przemysław Stelmaszyk</h6>
                                                <h6 class="m-0 text-primary">stelmaszyk@wp.pl</h6>
                                                <span class="">kom. 510 616 194</span>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="container-fluid">
                                          
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    wybierz:
                                                </div>
                                                <div class="col-9">
                                                    <select class="form-select">
                                                        <option value="1">Przemysław Stelmaszyk</option>
                                                        <option value="2">Jan Kowalski</option>
                                                        <option value="3">Tomasz Marciniak</option>
                                                        <option value="4">Karol Walerczyk</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">PODWYKONAWCY</b>
                                            <div class="container-fluid">
                                                <div class="row gx-0 mt-1 p-2">
                                                    <div class="col-12 text-secondary">Prace geodezyjne</div>
                                                    <div class="col-12 px-3 p-1">
                                                        <h6 class="m-0">Kartezjusz Kartuzy S.A.</h6>
                                                        <h6 class="m-0">Marcin Ekert</h6>
                                                        <h6 class="m-0">kom. 522 117 224</h6>
                                                    </div>
                                                </div>
                                                <div class="row gx-0 mt-1 p-2">
                                                    <div class="col-12 text-secondary">Prace ziemne</div>
                                                    <div class="col-12 px-3 p-1">
                                                        <h6 class="m-0">ADASTE</h6>
                                                        <h6 class="m-0">Przemysław Stelmaszyk</h6>
                                                        <h6 class="m-0">kom. 510 616 194</h6>
                                                    </div>
                                                
                                                </div>
                                               
                                                <div class="row gx-0 mt-1 p-2">
                                                    <div class="col-12 text-secondary">Odwodnienia</div>
                                                    <div class="col-12 px-3 p-1">
                                                        <h6 class="m-0">WOD-KAN Konin</h6>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body bg-light">
                                        <div class="container-fluid">
                                          <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    zakres prac:
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    osoba:
                                                </div>
                                                <div class="col-9">
                                                    <select class="form-select">
                                                        <option>wybierz</option>
                                                        <option value="1">Przemysław Stelmaszyk</option>
                                                        <option value="2">Jan Kowalski</option>
                                                        <option value="3">Tomasz Marciniak</option>
                                                        <option value="4">Karol Walerczyk</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">    
                                                <div class="col-3 pt-2">
                                                    firma:
                                                </div>
                                                <div class="col-9">
                                                    <select class="form-select">
                                                        <option>wybierz</option>
                                                        <option value="1">Ataner</option>
                                                        <option value="2">Skanska</option>
                                                        <option value="3">Solbud</option>
                                                        <option value="4">Probud</option>
                                                    </select>
                                                </div>
                                            </div>
                                             <div class="row gx-0 py-3">    
                                                <div class="col-3 pt-2">
                                                  
                                                </div>
                                                <div class="col-9">
                                                    <button class="btn btn-success">dodaj</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-12 col-lg-5 p-3 bg-light">
                     <span class="h5 fw-bold d-block">Informacje</span>
                     <div class="container-fluid mt-3">
                        <div class="row gx-0">
                          
                        </div>
                     </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Anuluj</button>
          <button type="button" class="btn btn-primary">Zapisz</button>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    name: "TableProjects",
    data() {
        return {
            getResult: null,
            modal: null
        }
    },
    methods: {
        async loadProjects() {
          try {
                const res = await axios.get("https://api.adaste.com/api/projects");
                const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
                };
                this.getResult=null;
                console.log(result);
                this.getResult = result.data;
            } catch (err) {
                this.getResult = err.response?.data || err;
            }
        }
    },
    mounted() {
        this.modal = new Modal(this.$refs.projectFormModal);
        this.loadProjects();
    },

    unmounted() {
        this.modal.hide();    
    }
   
}
</script>

<style>
  .tableRow:hover {
      color: red !important;
      cursor: pointer !important;
  }
</style>