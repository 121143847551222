<template>
  <div class="container p-2 mt-lg-5">
    <div class="row gx-0">
        <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 border shadow-lg rounded bg-white p-4  px-lg-5">
             <FormLogo />
                <Form @submit="handleAccountRequest" :validation-schema="schema" class="text-start">
                    <div class="form-group my-3">
                        <label for="username" class="text-secondary mb-1">Nazwa użytkownika (e-mail):</label>
                        <Field name="username" type="text" class="form-control form-control-lg" />
                        <ErrorMessage name="username" class="error-feedback text-danger" />
                    </div>
                    <div class="form-group my-3 ">
                        <label for="password" class="text-secondary mb-1">Hasło:</label>
                        <Field name="password" type="password" class="form-control form-control-lg" />
                        <ErrorMessage name="password" class="error-feedback text-danger" />
                    </div>
                    <div>
                      <span class="h6 small d-block">Dostęp do serwisu uzyskasz po aktywacji Twojego konta przez Administratora. Zostaniesz o tym powiadomiony drogą e-mailową.</span>
                      <span class="d-block mt-4 mb-2 h6 small fw-bold">więcej informacji: </span>
                      <div class="h6 small ms-4">      
                        <font-awesome-icon :icon="['fa', 'envelope']" class="mt-1 me-2" color="#2c3e50"/> kontakt@adaste.com
                      </div>
                      <div class="h6 small ms-4">                      
                        <font-awesome-icon :icon="['fa', 'phone']" class="mt-1 me-2 " color="#2c3e50"/> tel. 510 616 194
                      </div>
                    </div>
                    <div class="form-group text-center">
                    <button class="btn btn-success mt-3 py-3 pb-3 w-100 px-0 btn-lg btn-block" :disabled="loading">
                        <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm me-2"
                        ></span>
        
                        <span class="h6">wyślij prośbę o utworzenie konta</span>
                    </button>
                    </div>

                    <div class="form-group mt-3">
                      <div v-if="message" class="alert alert-danger p-1 text-center" role="alert">
                          {{ message }}
                      </div>
                    </div>
                </Form>
        
        </div>
    </div>
  </div>
  <div class="container mt-3">
      <div class="row">
        <div class="col-10 offset-1 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 ">
            <div class="container p-0 mt-2">
                <div class="row gx-0">
                    <div class="col-6 text-start p-0 h6">
                        <router-link to="login">
                            <font-awesome-icon :icon="['fas','chevron-left']" class="me-2 small" color="blue" />powrót do logowania
                        </router-link>
                    </div>
                    <div class="col-6 text-end p-0 h6"></div>
                </div>
                <div class="row mt-4 mt-lg-5">
                    <div class="col-12 text-secondary small">
                        <span class="d-block">&copy; 2021 adaste. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import FormLogo from "../elements/FormLogo.vue";

export default({
  "name": "AccountRequest",
    components: {
    Form,
    Field,
    ErrorMessage,
    FormLogo
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().email('Podaj poprawny adres e-mail').required("Podaj poprawny adres e-mail!"),
      password: yup.string().required("Podaj hasło!")
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
   methods: {
    handleAccountRequest(user) {
      this.loading = true;
        this.$store.dispatch("auth/register", user).then(
        () => {
          this.$router.push("/profile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
})
</script>

<style scoped>

</style>
