<template>
<div class="d-none position-absolute  pt-2 px-2 libraryTable" style="top:0; left:0; right:0;" id="TableCompanies">
    <div class="container-fluid bg-dark text-light border shadow-sm rounded mb-3">
        <div class="row gx-0 py-2">
            <div class="col-12">
                <span class="h4">FIRMY</span>
            </div>
        </div>
        <div class="row gx-0 p-3">
            <div class="col-6">
                filtry do tabeli firm
            </div>
            <div class="col-6 text-end">
                <button class="btn btn-success" @click="showCompaniesModal(0)">Nowa firma</button>
            </div>
        </div>
    </div>
    <div class="container-fluid p-0 m-0" style="overflow-x:hidden; overflow-y:scroll; height:72vh; max-height:72vh;">
        <div class="row gx-0 h6 border-bottom py-2 fw-bold bg-light position-sticky" style="top:0;">
            <div class="col-1">
                Lp.
            </div>
            <div class="col-2">
                typ
            </div>
            <div class="col-6 px-2">
                Nazwa
            </div>
            <div class="col-3">
                Miejscowość
            </div>
        </div>
         <div v-for="entry, key in getResult" :key="entry.id" class="row gx-0 py-1 tableRow" :id="'company_'+key" @click="showCompaniesModal(key)">
            <div class="col-1">{{ key }}.</div>
            <div class="col-2 text-start"></div>
            <div class="col-6 px-2 text-start companyName">{{ entry.company_basics.name }}</div>
            <div class="col-3 companyAddressCity">{{ entry.company_basics.address_city }}</div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row gx-0 border rounded h6 fw-bold m-0 py-3 bg-light position-sticky" style="bottom:-1px;">
            <div class="col-12">
                stronicowanie, narzędzia itp.
            </div>
        </div>
    </div>

  <div class="modal fade" ref="companyFormModal" id="companyFormModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header bg-dark">
           <div class="container-fluid">
                <div class="row gx-0">
                    <div class="col-12 col-md-6">
                        <input type="text" data-form="company_basics:name" class="form-control p-2" placeholder="Nazwa firmy" v-model="compName">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="container-fluid text-start">
              <div class="row gx-0">
                  <div class="col-12 col-xl-3 border p-3">
                     <span class="h5 fw-bold">Dane adresowe</span>
                     <div class="container-fluid mt-3">

                        <div class="row gx-0">
                            <div class="col-12 p-2">
                                <span class="h6">Ulica</span>
                                <input type="text" data-form="company_basics:address_street" class="form-control p-2"  v-model="compAddress_Street">
                            </div>
                            <div class="col-6 p-2">
                                <span class="h6">Nr budynku</span>
                                <input type="text" data-form="company_basics:address_building" class="form-control p-2"  v-model="compAddress_Building">
                            </div>
                            <div class="col-6 p-2">
                                <span class="h6">Nr lokalu</span>
                                <input type="text" data-form="company_basics:address_flat" class="form-control p-2"  v-model="compAddress_Flat">
                            </div>
                        </div>
                        <div class="row gx-0">
                            <div class="col-4 p-2">
                                <span class="h6">Kod pocztowy</span>
                                <input type="text" data-form="company_basics:address_postal_code" placeholder="00-000" class="form-control p-2"  v-model="compAddress_PostalCode">
                            </div>
                            <div class="col-8 p-2">
                                <span class="h6">Miejscowość</span>
                                <input type="text" data-form="company_basics:address_city" class="form-control p-2"  v-model="compAddress_City">
                            </div>                            
                        </div>
                        <div class="row gx-0">
                            <div class="col-12 p-2">
                                <span class="h6">Województwo</span>
                                <select data-form="company_basics:address_region" class="form-select" v-model="compAddress_Region">
                                    <option value="1">dolnośląskie</option>
                                    <option value="2">kujawsko-pomorskie</option>
                                    <option value="3">lubelskie</option>
                                    <option value="4">lubuskie</option>
                                    <option value="5">łódzkie</option>
                                    <option value="6">małopolskie</option>
                                    <option value="7">mazowieckie</option>
                                    <option value="8">opolskie</option>
                                    <option value="9">podkarpackie</option>
                                    <option value="10">podlaskie</option>
                                    <option value="11">pomorskie</option>
                                    <option value="12">śląskie</option>
                                    <option value="13">świętokrzyskie</option>
                                    <option value="14">warmińsko-mazurskie</option>
                                    <option value="15">wielkopolskie</option>
                                    <option value="16">zachodnio-pomorskie</option>
                                </select>
                            </div>
                            <div class="col-12 p-2">
                                <span class="h6 d-block">Kraj</span>
                                <select data-form="company_basics:address_country" class="form-select">
                                    <option value="1">Polska</option>
                                </select>
                            </div>                            
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-xl-3 p-3">
                     <span class="h5 fw-bold d-block">Dane rejestowe</span>
                     <div class="container-fluid mt-3">
                        <div class="row gx-0">
                            <div class="col-12 col-lg-6 p-2">
                                <span class="h6">NIP</span>
                                <input  data-form="company_registration_data:vat_tax_id" type="text" class="form-control p-2" v-model="compRegData_VatTaxId">
                            </div>
                            <div class="col-12 col-lg-6 p-2">
                                <span class="h6">REGON</span>
                                <input  data-form="company_registration_data:regon" type="text" class="form-control p-2" v-model="compRegData_Regon">
                            </div>
                            <div class="col-12 col-lg-6 p-2">
                                <span class="h6">KRS</span>
                                <input  data-form="company_registration_data:krs" type="text" class="form-control p-2" v-model="compRegData_Krs">
                            </div>
                        </div>
                        <div class="row gx-0">
                            <div class="col-6 p-2">
                                <div class="form-check form-switch">
                                    <input data-form="company_registration_data:whitelist" type="checkbox" class="form-check-input" id="companyWhitelist"  v-model="compRegData_WhiteList">
                                    <label class="form-check-label" for="companyWhitelist">Biała lista</label>
                                </div>
                            </div>
                        </div>
                     </div>

                     <span class="h5 fw-bold d-block mt-5">Typ podmiotu</span>
                     <div class="container-fluid mt-2">
                        <div class="row gx-0">
                            <div class="col-6 p-2">
                                <div class="form-check form-switch pb-1">
                                    <input data-form="company_type:investor" type="checkbox" class="form-check-input" v-model="compType_Investor">
                                    <label class="form-check-label">Inwestor</label>
                                </div>
                                <div class="form-check form-switch pb-1">
                                    <input data-form="company_type:general_contractor" type="checkbox" class="form-check-input" v-model="compType_GeneralContractor">
                                    <label class="form-check-label">Wykonawca generalny</label>
                                </div>
                                <div class="form-check form-switch pb-1">
                                    <input data-form="company_type:developer" type="checkbox" class="form-check-input" v-model="compType_Developer">
                                    <label class="form-check-label">Developer</label>
                                </div>
                                <div class="form-check form-switch pb-1">
                                    <input data-form="company_type:supervisor" type="checkbox" class="form-check-input" v-model="compType_Supervisor">
                                    <label class="form-check-label">Nadzór</label>
                                </div>
                                <div class="form-check form-switch pb-1">
                                    <input data-form="company_type:construction_manager" type="checkbox" class="form-check-input" v-model="compType_ConstructionManager">
                                    <label class="form-check-label">Kierownik Budowy</label>
                                </div>
                                 <div class="form-check form-switch pb-1">
                                    <input data-form="company_type:subcontractor" type="checkbox" class="form-check-input" v-model="compType_Subcontractor">
                                    <label class="form-check-label">Podykonawca</label>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>


                  <div class="col-12 col-xl-6 p-3 border-start">
                     <button id="addNewDepartmentButton" class="btn btn-success float-end" @click="addNewDepartment();">nowy dział</button>
                     <span class="h5 fw-bold d-block pt-1">Działy firmy</span>
                     
                     <div class="container-fluid mt-4">
                        <div class="row gx-0">
                           <div class="accordion" id="accordionCompanyDepartments">
                                <div v-for="department, key in compDepartments"  :key="key" class="accordion-item">
                                    <h2 class="accordion-header" :id='"heading"+department.internal_id'>
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target='"#collapse"+department.internal_id' aria-expanded="false" :aria-controls='"collapse"+department.internal_id'>
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">{{ department.label }}</b>
                                            <div class="px-0">
                                                <span class="text-primary me-5">{{ department.email }}</span>
                                                <span class="me-5">tel. {{ department.telephone }}</span>
                                                <span class="me-5">fax. {{ department.fax }}</span>
                                                <span class="">kom. {{ department.mobile }}</span>
                                            </div>
                                            <input :data-form="'company_office_department:'+key+':internal_id'" type="hidden" :value="department.internal_id">
                                        </div>
                                    </button>
                                    </h2>
                                    <div :id='"collapse"+department.internal_id' class="accordion-collapse collapse" :aria-labelledby='"heading"+department.internal_id' data-bs-parent="#accordionCompanyDepartments">
                                    <div class="accordion-body bg-light">
                                        <div class="container-fluid">
                                            <div class="row gx-0 py-1 pb-3 d-none">
                                                <div class="col-3 pt-2">   
                                                    <label class="form-check-label">kontakt na liście</label>
                                                </div>
                                                <div class="col-9 pt-2">
                                                    <div class="form-check form-switch">
                                                    <input type="checkbox" class="form-check-input">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    nazwa:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-form="'company_office_department:'+key+':label'" type="text" class="form-control" :value="department.label">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    e-mail:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-form="'company_office_department:'+key+':email'" type="text" class="form-control" :value="department.email">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    telefon:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-form="'company_office_department:'+key+':telephone'" type="text" class="form-control" :value="department.telephone">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    fax:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-form="'company_office_department:'+key+':fax'" type="text" class="form-control" :value="department.fax">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    tel. komórkowy:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-form="'company_office_department:'+key+':mobile'" type="text" class="form-control" :value="department.mobile">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    info:
                                                </div>
                                                <div class="col-9">
                                                    <textarea :data-form="'company_office_department:'+key+':info'" type="text" class="form-control text-start" v-model="department.info"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="accordion-item new-item d-none">
                                    <h2 class="accordion-header" id="headingNew">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNew" aria-expanded="false" aria-controls="collapseThree">
                                        <div>
                                            <b class="h6 fw-bold mb-3 d-block">NOWY DZIAŁ</b>
                                            <div class="px-0">
                                                <span class="text-primary me-5">email</span>
                                                <span class="me-5">tel.</span>
                                                <span class="me-5">fax.</span>
                                                <span class="">kom.</span>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseNew" class="accordion-collapse collapse collapse-new" aria-labelledby="headingNew" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="container-fluid">
                                            <div class="row gx-0 py-1 pb-3 d-none">
                                                <div class="col-3 pt-2">   
                                                    <label class="form-check-label">kontakt na liście</label>
                                                </div>
                                                <div class="col-9 pt-2">
                                                    <div class="form-check form-switch">
                                                    <input type="checkbox" class="form-check-input">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    nazwa:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-new-department="'company_office_department:'+companyDepartmentsNumber+':label'" type="text" class="form-control" :value="'NOWY DZIAŁ nr'+ companyDepartmentsNumber ">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    e-mail:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-new-department="'company_office_department:'+companyDepartmentsNumber+':email'" type="text" class="form-control" value="">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    telefon:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-new-department="'company_office_department:'+companyDepartmentsNumber+':telephone'" type="text" class="form-control" value="">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    fax:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-new-department="'company_office_department:'+companyDepartmentsNumber+':fax'" type="text" class="form-control" value="">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    tel. komórkowy:
                                                </div>
                                                <div class="col-9">
                                                    <input :data-new-department="'company_office_department:'+companyDepartmentsNumber+':mobile'" type="text" class="form-control" value="">
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-1">
                                                <div class="col-3 pt-2">
                                                    info:
                                                </div>
                                                <div class="col-9">
                                                    <textarea :data-new-department="'company_office_department:'+companyDepartmentsNumber+':info'" type="text" class="form-control text-start"></textarea>
                                                </div>
                                            </div>
                                            <div class="row gx-0 py-3">
                                                <div class="col-3 pt-2">
                                                   <button class="btn btn-secondary btn-lg" @click="cancelNewDepartment();">ANULUJ</button>
                                                </div>
                                                <div class="col-9 pt-2 text-end">
                                                    <button class="btn btn-success btn-lg" @click="saveNewDepartmentData()">ZAPISZ</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>

                  </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="modal.hide()">Anuluj</button>
          <button type="button" class="btn btn-primary" @click="saveCompanyData()">Zapisz</button>
        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import config from '../../../config/app.config.js';

const API_SERVER = config.apiServer.url;

export default {
    name: "TableCompanies",
  
    data() {
        return {
            getResult: null,
            modal: null,
            companyData: null,
            currentCompanyObject: null,
            saveResult: false,
            departmentsNumber: 0
        }
    },
    computed: {
      compName: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.name : ""} },  
      compAddress_Street: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_street : ""} },  
      compAddress_Building: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_building : ""} },  
      compAddress_Flat: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_flat : ""} },  
      compAddress_PostalCode: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_postal_code : ""} },  
      compAddress_City: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_city : ""} },  
      compAddress_Region: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_region : ""} },  
      compAddress_Country: { get() {return this.companyData && this.companyData.company_basics ? this.companyData.company_basics.address_country : ""} }, 
      compRegData_VatTaxId: { get() {return this.companyData && this.companyData.company_registration_data ? this.companyData.company_registration_data.vat_tax_id : ""} },
      compRegData_Regon: { get() {return this.companyData && this.companyData.company_registration_data ? this.companyData.company_registration_data.regon : ""} },
      compRegData_Krs: { get() {return this.companyData && this.companyData.company_registration_data ? this.companyData.company_registration_data.krs : ""} },
    
      compRegData_WhiteList: { get() { return this.verifyCompanyField('company_registration_data','whitelist'); } },
      compType_Investor: { get() { return this.verifyCompanyField('company_type','investor'); } },
      compType_GeneralContractor: { get() { return this.verifyCompanyField('company_type','general_contractor'); } },
      compType_Developer: { get() { return this.verifyCompanyField('company_type','developer');}},
      compType_Supervisor: { get() {return this.verifyCompanyField('company_type','supervisor'); }},
      compType_ConstructionManager: { get() {return this.verifyCompanyField('company_type','construction_manager');} },
      compType_Subcontractor: { get() {return this.verifyCompanyField('company_type','subcontractor'); } },
    
      compDepartments: { get() {return this.companyData ? this.companyData.company_office_department : null} },
      companyDepartmentsNumber: { get() {return this.departmentsNumber; }}
    },
    methods: {
        
        verifyCompanyField(group, type) {
            if (this.companyData !== null) {
                                if (Object.prototype.hasOwnProperty.call(this.companyData, group)) {
                                        return this.companyData[group][type];
                                    } else {
                                        return false;
                                    }
                                } else {
                                     return false; 
                                }
        },

        showCompaniesModal(object) {
            this.modal.show();  
            this.loadCompanyData(object);
            this.currentCompanyObject = object;
            
        },
        async loadCompanies() {
          try {
            this.getResult=null;
                const res = await axios.get(API_SERVER+"/api/companies");
                const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
                };
                
                //console.log(result);
                this.getResult = result.data;
            } catch (err) {
                this.getResult = err.response?.data || err;
            }
        },

        async loadCompanyData(id) {
          try {
              this.companyData=null;
              if (id>0) {
                const res = await axios.get(API_SERVER+"/api/companies?id="+id);
                const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
                };
                this.companyData = result.data[id];
                // console.log(result.data);
              }
            } catch (err) {
                this.companyData = err.response?.data || err;
            }
        },


        addNewDepartment() {
            let acItems = document.getElementsByClassName("accordion-item");
            for (let i=0; i<acItems.length; i++) {
                acItems[i].classList.add("d-none");
            }
            document.getElementsByClassName("new-item")[0].classList.remove("d-none");
            document.getElementById("addNewDepartmentButton").disabled = true;
            this.departmentsNumber = this.companyData ? Object.prototype.hasOwnProperty.call(this.companyData, 'company_office_department') ?  Object.keys(this.companyData.company_office_department).length : 0 :0;
        },

        cancelNewDepartment() {

            let acItems = document.getElementsByClassName("accordion-item");
            for (let i=0; i<acItems.length; i++) {
                acItems[i].classList.remove("d-none");
            }
            document.getElementsByClassName("new-item")[0].classList.add("d-none");
            document.getElementById("addNewDepartmentButton").disabled = false;
        },

        saveCompanyData(modalClose = true) {
            let dataSet = document.querySelectorAll("[data-form]"), item, value;
           
            let companyData = {};
            for (let i=0; i<dataSet.length; i++) {
              
                item = dataSet[i].getAttribute('data-form').split(":");

                switch (dataSet[i].type) {
                    case "text":
                        value = dataSet[i].value;
                        break;
                    case "checkbox":
                        value = dataSet[i].checked;
                        break;    
                    default:
                        value = dataSet[i].value;
                        break;    
                }
                
        
              switch (item.length) {
                    case 2:
                        if (!Object.prototype.hasOwnProperty.call(companyData, item[0])) { companyData[item[0]] = {}; }
                        companyData[item[0]][item[1]] = value;
                     
                        break;
                    case 3:
                        if (!Object.prototype.hasOwnProperty.call(companyData, item[0])) { companyData[item[0]] = {}; }
                        if (!Object.prototype.hasOwnProperty.call(companyData[item[0]], item[1])) { companyData[item[0]][item[1]] = {}; }
                        companyData[item[0]][item[1]][item[2]] = value;
                        break;    
                }
            }
           
            try {

              let object = this.currentCompanyObject; 
              let postData = [{objectType: 'company', objectId: parseInt(object), dataSet: companyData}];

              const params = new URLSearchParams();
              params.append('operator', this.$store.state.auth.user.id);
              params.append('data', JSON.stringify(postData));                
            
            console.log(params);
              axios.post(API_SERVER+"/api/communication/updateIntelligence", params)
                .then( function(res) {
                        const result = {
                        status: res.status,
                        headers: res.headers,
                        data: res.data,
                        };
                        if (result.status === 200) {
                            console.log("Object Update OK");
                            }
                             
                        }
                    );

            } catch (err) {
                console.log(err);
            } finally {
                if (modalClose) {
                    this.modal.hide();        
                    if (this.currentCompanyObject >0) {
                        document.querySelector('[id="company_'+this.currentCompanyObject+'"]').querySelector(".companyName").innerHTML =  document.querySelector('[data-form="company_basics:name"]').value;
                        document.querySelector('[id="company_'+this.currentCompanyObject+'"]').querySelector(".companyAddressCity").innerHTML =  document.querySelector('[data-form="company_basics:address_city"]').value;
                    } else {
                        this.loadCompanies();
                    }
                }
            }
        },

        saveNewDepartmentData() {
        
            let dataSet = document.querySelectorAll("[data-new-department]"), item, value;
           
            let departmentData = {};
            for (let i=0; i<dataSet.length; i++) {
              
                item = dataSet[i].getAttribute('data-new-department').split(":");

                switch (dataSet[i].type) {
                    case "text":
                        value = dataSet[i].value;
                        break;
                    case "checkbox":
                        value = dataSet[i].checked;
                        break;    
                    default:
                        value = dataSet[i].value;
                        break;    
                }
                
        
              switch (item.length) {
                    case 2:
                        if (!Object.prototype.hasOwnProperty.call(departmentData, item[0])) { departmentData[item[0]] = {}; }
                        departmentData[item[0]][item[1]] = value;
                     
                        break;
                    case 3:
                        if (!Object.prototype.hasOwnProperty.call(departmentData, item[0])) { departmentData[item[0]] = {}; }
                        if (!Object.prototype.hasOwnProperty.call(departmentData[item[0]], item[1])) { departmentData[item[0]][item[1]] = {}; }
                        departmentData[item[0]][item[1]][item[2]] = value;
                        break;    
                }
            }
           
            if (departmentData !== {} ) {
                let data = departmentData['company_office_department'][this.companyDepartmentsNumber];
                this.companyData === null ? this.companyData = {} : null;
                !Object.prototype.hasOwnProperty.call(this.companyData, 'company_office_department') ? this.companyData.company_office_department = [] : null;
                this.companyData.company_office_department.push(data);
                this.cancelNewDepartment();
                }
            
        }
    },
    mounted() {
        this.modal = new Modal(this.$refs.companyFormModal);
        this.loadCompanies();
    },

    unmounted() {
        this.modal.hide();    
    }
   
}
</script>

<style scoped>
  .tableRow:hover {
      color: red !important;
      cursor: pointer !important;
  }
</style>