<template>
<div id="menu" class="container-fluid p-0 border-end">
    <div class="row gx-0 h-100">
        <div class="h-100 col-2">
            
            <div class="container-fluid">
                <div class="row gx-0 ">
                    <router-link to="/dashboard" tag="div" id="menu-dashboard" class="col-12 py-3 icon text-decoration-none border-bottom">
                        <i class="fas fa-chart-line fa-lg"></i>
                        <small class="d-block">dashboard</small>
                    </router-link>
                    <router-link to="/projects" tag="div" id="menu-projects" class="col-12 py-3 icon text-decoration-none ">
                        <i class="fas fa-building fa-lg"></i>
                        <small class="d-block">projekty</small>
                    </router-link>  
                    <router-link to="/imports" tag="div" id="menu-imports" class="col-12 py-3 icon text-decoration-none  border-top">
                        <i class="fas fa-file-import fa-lg"></i>
                        <small class="d-block">importy</small>
                    </router-link>    
                    <router-link to="/libraries" tag="div" id="menu-libraries" class="col-12 py-3 icon text-decoration-none ">
                        <i class="fas fa-address-book fa-lg"></i>
                        <small class="d-block">kartoteki</small>
                    </router-link>   
                     <router-link to="/settings" tag="div" id="menu-settings" class="col-12 py-3 icon text-decoration-none  border-top">
                        <i class="fas fa-cogs fa-lg"></i>
                        <small class="d-block">ustawienia</small>
                    </router-link> 
                     <router-link to="/log" tag="div" id="menu-log" class="col-12 py-3 icon text-decoration-none  border-top">
                        <i class="fas fa-list fa-lg"></i>
                        <small class="d-block">LogSystem</small>
                    </router-link> 
                </div>
            </div>

           
        </div>
        <div class=" bg-light text-dark h-100 col-10 py-2">
            <component v-bind:is="currentMenuContext" ></component>
        </div>
    </div>
</div>

</template>

<script>
import { useRouter } from 'vue-router';
import Dashboard from './menuTools/dashboard';
import Imports from './menuTools/imports';
import Libraries from './menuTools/libraries';
import Projects from './menuTools/projects';
import Settings from './menuTools/settings';
import Log from './menuTools/log';

    export default {
    name: "Main Menu",
    components: { Dashboard, Imports, Libraries, Projects, Settings, Log },
   
    data:  function() {return {
            context: 'dashboard'
        }
    },

    mounted() {
        let router = useRouter();
        let element = router.currentRoute.value.path.split("/")[1];

        let menuElements = document.getElementsByClassName("icon");
        for (let i=0; i<menuElements.length; i++) {
           
           
            if (menuElements[i].id !== "menu-"+element) {
                document.getElementById(menuElements[i].id).classList.remove("bg-light","text-dark");
                document.getElementById(menuElements[i].id).classList.add("text-secondary");
            } else {
                document.getElementById(menuElements[i].id).classList.remove("text-secondary");
                document.getElementById(menuElements[i].id).classList.add("bg-light","text-dark");
            }
       
        } 
        
    },

    computed: {
        currentMenuContext: function() {
          let router = useRouter();
          let path = router.currentRoute.value.path.split("/")[1];
            return path; 
        }
    }
}
</script>

<style>
  .icon:hover {
      color: red !important;
      cursor: pointer !important;
  }
</style>