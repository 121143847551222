import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/pages/Home.vue";
import Login from "../components/pages/Login.vue";
import AccountRequest from "../components/pages/AccountRequest.vue";
import ResetPassword from "../components/pages/ResetPassword.vue";
import Dashboard from "../components/pages/Dashboard.vue";
import Projects from "../components/pages/Projects.vue";
import Imports from "../components/pages/Imports.vue";
import Libraries from "../components/pages/Libraries.vue";
import Settings from "../components/pages/Settings.vue";
import Log from "../components/pages/Log.vue";
// lazy-loaded
//const Profile = () => import("../components/Profile.vue")


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/account-request",
    component: AccountRequest,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/imports",
    component: Imports,
  },
  {
    path: "/libraries",
    component: Libraries,
  },
  {
    path: "/log",
    component: Log,
  },
  {
    path: "/settings",
    component: Settings,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
    const publicPages = ['/','/login','/account-request','/reset-password'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  });